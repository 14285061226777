<template>
  <div class="menu-links">
    <div v-if="!getMenuMobilePermissions.length">
      <div v-for="(n, index) in 5" class="item load pb-5 mb-5" :key="index">
        <div class="menu-item-loading">
          <div class="icone"></div>
          <div class="dado">
            <div class="up">
              <span>--</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <template v-for="(menu, i) in getMenuMobilePermissions">
        <a v-if="
          (((!viewMoreMenu && i < indexViewMore) || (viewMoreMenu && i < indexViewMore)) &&
            menu.name === 'Play' &&
            !$store.getters.setPanel) ||
          (((!viewMoreMenu && i < indexViewMore) || (viewMoreMenu && i < indexViewMore)) &&
            menu.name === 'Play' &&
            $store.getters.setPanel)
        " @click="eventLoginClub" :class="'menu-item-link menu-key-' + i" :key="i">
          <img :src="getImgUrlMenus(menu.name.toLowerCase())" :title="$t('side.' + menu.title)"
          :alt="$t('side.' + menu.title)" />
          {{ $t("side." + menu.name) }}
        </a>
        <router-link v-if="
          (((!viewMoreMenu && i < indexViewMore) || (viewMoreMenu && i < indexViewMore)) &&
            menu.name !== 'Sales' &&
            menu.name !== 'Contracts' && 
            menu.name !== 'Commissions' &&
            menu.name !== 'Play' &&
            !$store.getters.setPanel) ||
          (((!viewMoreMenu && i < indexViewMore) || (viewMoreMenu && i < indexViewMore)) &&
            menu.name !== 'Sales' &&
            menu.name !== 'Contracts' && 
            menu.name !== 'Commissions' &&
            menu.name !== 'Play' &&
            $store.getters.setPanel)
        " :to="`/${$t('route.' + menu.name.toLowerCase())}`" :class="'menu-item-link menu-key-' + i" :key="i">
          <img :src="getImgUrlMenus(menu.name.toLowerCase())" :title="$t('side.' + menu.title)"
            :alt="$t('side.' + menu.title)" />
          {{ $t("side." + menu.title) }}
          <span v-if="menuNamesWithNewTag.includes(menu.name.toLowerCase())" class="menu-new">Novo</span>
        </router-link>
        <a 
          v-if="
            (((!viewMoreMenu && i < indexViewMore) || (viewMoreMenu && i < indexViewMore)) &&
              menu.name === subMenuEnabled &&
              !$store.getters.setPanel) ||
            (((!viewMoreMenu && i < indexViewMore) || (viewMoreMenu && i < indexViewMore)) &&
              menu.name === subMenuEnabled &&
              $store.getters.setPanel)
          " 
          @click="subMenu01" 
                    :class="'menu-item-link menu-key-' + i" 
          :key="i"
        >
          <img :src="getImgUrlMenus(menu.name.toLowerCase())" />
          {{ $t("side." + menu.name) }}
          <img src="@/assets/img/icons/arrow-fill.svg" class="arrow" :class="{ rotateArrow: sub_menu }" />
        </a>
      </template>

      <a @click="subMenu02" v-if="(viewMoreMenu && $store.getters.setPanel) || !$store.getters.setPanel">
        <img src="@/assets/img/icons/more.svg" />{{ $t("side.text_1970") }}
        <img src="@/assets/img/icons/arrow-fill.svg" class="arrow" :class="{ rotateArrow: sub_menu2 }" />
      </a>

      <Logout />

      <ul class="sub-menu" id="sub_01" v-show="sub_menu">
        <li @click="sub_menu = false">
          <template v-for="(menu, i) in getSubmenuSalesPermissions">
            <router-link v-if="menu.name === 'Sales'" :to="`/${$t('route.' + menu.name.toLowerCase())}`"
              :class="'menu-key-' + i" :key="i">
              <img :src="getImgUrlMenus(menu.name.toLowerCase())" :title="$t('side.' + menu.title)"
                :alt="$t('side.' + menu.title)" />
              {{ $t("side." + menu.title) }}
            </router-link>
            <router-link v-if="menu.name === 'Contracts'" :to="`/${$t('route.' + menu.name.toLowerCase())}`"
              :class="'menu-key-' + i" :key="i">
              <img :src="getImgUrlMenus(menu.name.toLowerCase())" :title="$t('side.' + menu.title)"
                :alt="$t('side.' + menu.title)" />
              {{ $t("side." + menu.title) }}
            </router-link>
            <router-link v-if="
              menu.name === 'Commissions' && ambassadorEnabled && ambassadorAcceptedTerms
            " :to="`/${$t('route.' + menu.name.toLowerCase())}`" :class="'menu-key-' + i" :key="i">
              <img :src="getImgUrlMenus(menu.name.toLowerCase())" :title="$t('side.' + menu.title)"
                :alt="$t('side.' + menu.title)" />
              {{ $t("side." + menu.title) }}
            </router-link>
            <router-link v-if="
              menu.name === 'Commissions' && ambassadorEnabled && !ambassadorAcceptedTerms
            " :to="`/termos-comissoes`" :class="'menu-key-' + i" :key="i">
              <img :src="getImgUrlMenus(menu.name.toLowerCase())" :title="$t('side.' + menu.title)"
                :alt="$t('side.' + menu.title)" />
              {{ $t("side." + menu.title) }}
            </router-link>
          </template>
        </li>
      </ul>

      <ul class="sub-menu sub-menu2" id="sub_02" v-show="sub_menu2" v-if="viewMoreMenu">
        <li @click="sub_menu2 = false">
          <b-tooltip target="club-menu" :title="$t('side.text_1971')" placement="left" />
          <template v-for="(menu, i) in getMenuMobilePermissions">
            <a v-if="
              viewMoreMenu &&
              i > (indexViewMore-1) &&
              menu.name === 'Play' &&
              (!$store.getters.setPanel || $store.getters.setPanel)
            " @click="eventLoginClub" :id="menu.name.toLowerCase()" :class="'menu-key-' + i" :key="i">
              <img :src="getImgUrlMenus(menu.name.toLowerCase())" />
              {{ $t("side." + menu.title) }}
            </a>

            <router-link v-if="
              viewMoreMenu &&
              i > (indexViewMore-1) &&
              menu.name !== 'Play' &&
              (!$store.getters.setPanel || $store.getters.setPanel)
            " :id="menu.name.toLowerCase()" :class="'menu-key-' + i" :to="`/${$t('route.' + menu.name.toLowerCase())}`"
              :key="i">
              <img :src="getImgUrlMenus(menu.name.toLowerCase())" />
              {{ $t("side." + menu.title) }}
              <span v-if="menuNamesWithNewTag.includes(menu.name.toLowerCase())" class="menu-new">Novo</span>
            </router-link>
          </template>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";
import Vue from "vue";
import { mapGetters } from "vuex";
import Logout from "./Logout.vue";
export default {
  name: "Menu",
  mixins: [Utils],
  components: { Logout },
  data() {
    return {
      sub_menu: false,
      sub_menu2: false,
      sub_menu1_inserted: false,
      item: null,
      top: 0,
      bottom: 0,
      fimMenu: 300,
      fimSubmenu: 0,
      dashboard: false,
      vendas: false,
      cupom: false,
      id: 0,
      screenHeight: window.innerHeight,
      heightBreakpoints: [460, 560, 640, 740, 840, 940, 1024],
      menuNamesWithNewTag: ['integrations', 'showcase']
    };
  },
  computed: {
    ...mapGetters(["getMenuMobilePermissions", "getSubmenuSalesPermissions", "viewMoreMenu"]),

    clubIntegration() {
      return this.$store.getters.userClub;
    },
    subMenuEnabled() {
      let enabled = this.$store.getters.getMenuMobilePermissions.filter((menu) => {
        if (menu.name === "Sales") {
          return menu;
        } else if (menu.name === "Contracts") {
          return menu;
        } else if (menu.name === "Commissions") {
          return menu;
        }
      });
      return enabled.length ? enabled[0].name : false;
    },
    indexViewMore() {
      return this.updateHeightMenu()
    },
  },
  methods: {
    updateHeightMenu() {
      this.screenHeight = window.innerHeight;

      // Calcula o índice baseado no breakpoint mais alto que é menor ou igual à altura da tela
      let i = this.heightBreakpoints.findIndex(bp => this.screenHeight <= bp) + 1;
      if (i === 0) i = this.heightBreakpoints.length + 1; // Para telas maiores que o maior breakpoint

      // Incrementa para menus adicionais
      this.$store.getters.getMenuMobilePermissions.forEach((menu) => {
        if (['Contracts', 'Commissions'].includes(menu.name)) {
          i += 1;
        }
      });

      return i;
    },
    getImgUrlMenus(icon) {
      var images = require.context(
        "@/assets/img/icons/",
        false,
        /\.svg$/
      );
      return images("./" + icon + ".svg");
    },
    eventLoginClub() {
      Vue.swal({
        title: this.$t("side.text_1973"),
        text: this.$t("side.text_1971"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Entrar no Voomp Play",
        cancelButtonText: this.$t("side.text_1975"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("clubLogin").catch(function () { });
        }
        if (result.dismiss == "cancel") {
          this.$router.push("/produtos");
        }
      });
    },
    subMenu01(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop;
      const submenu = document.querySelector("#sub_01");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 71;
      this.sub_menu = !this.sub_menu;
      this.sub_menu2 = false;
    },
    subMenu02(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop - 400;
      const submenu = document.querySelector("#sub_02");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 20;
      this.sub_menu2 = !this.sub_menu2;
      this.sub_menu = false;
    },
    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false;
          this.sub_menu2 = false;
        }
      });
    },
    openAmbassadorTerms() {
      this.$bvModal.show("ambassador-terms");
    },
  },
  mounted() {
    this.eventMouse();
  },
  created() {
    // Adiciona um listener para o evento de redimensionamento da janela
    window.addEventListener('resize', this.updateHeightMenu);
  },
  destroyed() {
    // Remove o listener quando o componente for destruído
    window.removeEventListener('resize', this.updateHeightMenu);
  },
};
</script>

<style scoped>
.ajust-itens {
  height: 15vh !important;
}

.menu-links {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: flex-start;
  width: 100%;
  padding: 0 !important;
}

.menu-links a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 230px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}

.menu-links a:hover {
  text-decoration: none;
  color: #002363;
}

.menu-links a img {
  filter: invert(50%);
  /* deixar o icone preto */
  /* filter: brightness(0) invert(0);; */
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 40px;
  max-width: 22px;
}

.menu-links a.router-link-exact-active img,
.menu-links a:hover img {
  filter: invert(0);
}

.menu-links a.router-link-exact-active,
.menu-links a:hover {
  color: #002363 !important;
}

.menu-links a.router-link-exact-active::before {
  content: "";
  width: 7px;
  height: 7px;
  background: #002363;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  transition: all 0.3s ease;
}

.menu-item-link {
  padding-bottom: 60px !important; 
}

img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}

/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 200px;
  /* top: 200px; */
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}

#sub_02 {
  width: 280px;
  max-height: 600px;
  overflow-y: auto;
}

#sub_03 {
  width: 280px;
}

.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;

  /* background: silver; */
}

.sub-menu li a.router-link-exact-active::before {
  /* display: block;
  position: absolute; */
  left: -15px;
}

.sub-menu li a:hover {
  text-decoration: none;
  color: #000;
}

.sub-menu li a+a {
  margin-top: 20px;
}

.sub-menu li a img {
  margin-right: 25px;
}

.Menu:hover .menu-links a {
  color: #333;
}

/* --- */
.sub-menu2 {
  top: 300px;
}

.sub-menu3 {
  top: 300px;
}

.rotateArrow {
  transform: rotate(-90deg);
}

.logo-club {
  max-width: 115px !important;
  width: 115px;
  margin-left: -3px !important;
}

.menu-new {
  position: relative;
  background: #EEF2FF;
  border: 1px solid #2A63AB;
  color: #0854C5;
  height: 20px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  top: -18px;
}

.load .menu-item-loading img {
  filter: invert(50%);
}

.load .up h5,
.load .up span {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 200px;
  color: transparent;
  display: block;
  animation: preload 0.8s forwards linear infinite alternate;
}

.load .up h5 {
  width: 120px;
  height: 15px;
  border-radius: 3px;
}

.load .up span {
  width: 80px;
  height: 15px;
  border-radius: 3px;
}

.menu-item-loading {
  display: flex;
  align-items: center;
}

.menu-item-loading .icone {
  width: 24px;
  height: 24px;
  padding: 14px;
  background: #f7f7f7;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  /* Adicionando a animação */
  animation: background-opacity 3s infinite alternate;
}

@keyframes background-opacity {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 2.5;
  }
}

.up {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}

.up h5 {
  font-size: 13px;
  font-weight: 600;
}

.up span {
  font-size: 13px;
  color: #81858e;
  font-weight: normal;
}
</style>
